@tailwind base;
@tailwind components;
@tailwind utilities;


/** Animate on Scroll **/
$aos-distance: 16px;
@import 'aos/src/sass/aos.scss';

/** Fonts **/
body {
  font-family: Geologica;
  @apply text-gray-600;
}
h1, h2, h3 {
  &:not(.text-white):not(.text-neutral-100):not(.text-gray-100) {
    @apply text-gray-900;
  }
}
h1 {
  font-weight: 800;
}
h2 {
  font-weight: 700;
}
h3 {
  font-weight: 600;
}

/* Add this CSS to your component's stylesheet or within a <style> tag */
.card-hover-effect {
  // transition: transform 100ms ease, box-shadow 250ms ease, border-color 50ms ease;
}


@tailwind base;
@tailwind components;
@tailwind utilities;


/** Animate on Scroll **/
$aos-distance: 16px;
@import 'aos/src/sass/aos.scss';

/** Fonts **/
body {
  font-family: Geologica;
  @apply text-gray-600;
}
h1, h2, h3 {
  &:not(.text-white):not(.text-neutral-100):not(.text-gray-100) {
    @apply text-gray-900;
  }
}
h1 {
  font-weight: 800;
}
h2 {
  font-weight: 700;
}
h3 {
  font-weight: 600;
}

/* Add this CSS to your component's stylesheet or within a <style> tag */
.card-hover-effect {
  // transition: transform 100ms ease, box-shadow 250ms ease, border-color 50ms ease;
}

