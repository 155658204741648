body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Floating iPhone */
.cardRef-sheen {
  overflow: hidden;
}

.cardRef-sheen::before {
  content: "";
  position: absolute;
  top: 0;
  left: var(
    --sheen-left,
    -75%
  ); /* Use the variable, defaulting to 0% if not set */
  height: 100%;
  width: 50%;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.25) 0%,
    transparent 100%
  );
  transform: skewX(-25deg);
  z-index: 2;
  display: block;
}

.cardRef-sheen {
  overflow: hidden;
  clip-path: polygon(20% 0, 100% 0, 100% 0, 80% 100%, 0 100%);
}
